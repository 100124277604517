import React from 'react';
import classes from '../modules/Welcome.module.scss'
import {useTranslation} from "react-i18next";
import {Button} from "@nextui-org/react";

interface WelcomeProps{
    scrollToComponent : (arg0: string) => void
}
function Services() {
    // const { t } = useTranslation();
    // return (
    //     <div className={classes['welcome-wrapper']}>
    //
    //         <div className={classes['headers-container']}>
    //             <div className={classes['header']}>
    //                 <div className={classes.header1}>{t('welcome.title')}</div>
    //                 <div className={classes.header2}>{t('welcome.desc')}</div>
    //             </div>
    //         </div>
    //
    //     </div>
    // );
    return <></>
}

export default Services;