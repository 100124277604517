import React, {FormEvent, useEffect, useState} from 'react';
import classes from '../modules/Contact.module.scss'
import axios from "axios";
import {Button, Input, Textarea} from "@nextui-org/react";
import {CartProps} from "../models/cart.model";
import Cart from "./Cart";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
interface FormData {
    firstName: string;
    lastName: string;
    email:string
    company: string;
    message: string;
}
const Contact = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState<FormData>({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        message:''
    })
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] =  useState(false);
    const aggregatedCart: { [key: string]: number } = {};
    // props.cart.forEach((item) => {
    //     const key = `${i18n.language === 'en' ? item.titleEng : item.titleGeo} - ${item.price}$`;
    //     aggregatedCart[key] = (aggregatedCart[key] || 0) + 1;
    // });

    const cartItems: string[] = [];
    for (const key in aggregatedCart) {
        const count = aggregatedCart[key];
        cartItems.push(`${count}x ${key}`);
    }

    const cartString = cartItems.join('\n');

    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            message: cartString
        }));
    }, [cartString]);
    const handleChange = (e : any) => {
        const { name, value} = e.target;
        setFormData({...formData,[name]: value});

    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const requiredFields : (keyof FormData)[] = ['firstName', 'lastName', 'email', 'company', 'message'];
        const emptyFields = requiredFields.filter(field => !formData[field]);

        if (emptyFields.length > 0) {
            setError(`Please fill out your Order`);
            return;
        }
        setLoading(true);
        setError(null);

        try {
            await axios.post<void>('http://localhost:5000/send-email', formData);
            setSuccess(true);
            setError(null);
        } catch (error) {
            setSuccess(false)
            setError('Failed to send email');
        }

        setLoading(false);
    }

    return (
        <div className={classes['contact-wrapper']}>
            <div className={classes.contact}>
                <div className={classes['contact-title']}>
                    <h1>{t('contact.title')}</h1>
                    <h3>{t('contact.desc')}</h3>
                    <div className={classes['contact-body']}>
                        {success && <p> {t('contact.orderSuccess')} </p>}
                        {error && <p>{error}</p>}
                        <form onSubmit={handleSubmit}>
                            <div className={classes['form-row']}>
                                <div className="input-data">
                                    <Input type="text"
                                           size="sm"
                                           label={`${t("contact.firstName")}`}
                                           id="firstName"
                                           name="firstName"
                                           value={formData.firstName}
                                           onChange={handleChange}
                                           isRequired/>
                                </div>
                                <div className={classes['input-data']}>
                                    <Input type="text"
                                           size="sm"
                                           label={`${t("contact.lastName")}`}
                                           id="lastName"
                                           name="lastName"
                                           value={formData.lastName}
                                           onChange={handleChange}
                                           isRequired/>
                                </div>
                            </div>
                            <div className={classes['form-row']}>
                                <div className={classes['input-data']}>
                                    <Input type="email"
                                           size="sm"
                                           label={`${t("contact.email")}`}
                                           id="email"
                                           name="email"
                                           value={formData.email}
                                           onChange={handleChange}
                                           isRequired/>
                                </div>
                                <div className={classes['input-data']}>
                                    <Input type="text"
                                           size="sm"
                                           label={`${t("contact.company")}`}
                                           id="company"
                                           name="company"
                                           value={formData.company}
                                           onChange={handleChange}
                                           isRequired/>
                                </div>
                            </div>
                            <div className={classes['form-row']}>
                                <Textarea
                                        type="text"
                                       size="sm"
                                        label={`${t("contact.order")}`}
                                       id="message"
                                       name="message"
                                       value={formData.message}
                                       onChange={handleChange}
                                       readOnly={cartItems.length !== 0}
                                       isRequired/>
                            </div>
                            <Button type="submit" disabled={loading}>
                                {loading ? t("contact.loading") : t("contact.submit") }
                            </Button>
                        </form>
                    </div>
                </div>
                <div className={classes['contact-call']}>
                    <h1>{t('contact.title-call')}</h1>
                    <h2>{t('contact.call-desc')}</h2>
                </div>
            </div>

        </div>

    );
}

export default Contact;