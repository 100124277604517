import React from 'react';
import classes from '../modules/Scaffolding.module.scss';
import Scaffolding from "./Scaffolding";
import scaffoldingData from "../content/scaffoldings.json";
import { ScaffoldingData } from "../models/scaffolding.model";
import { useTranslation } from "react-i18next";

interface AddToCart {
    addToCart: (images: string[]) => void;
}

function Scaffoldings(props: AddToCart) {
    const data: ScaffoldingData = scaffoldingData;
    const { t } = useTranslation();

    return (
        <div className={classes.scaffoldingWrapper}>
            <div className={classes['scaffolding-title']}>
                <h1>{t('scaffoldings.title')}</h1>
                <h3>{t('scaffoldings.desc')}</h3>
            </div>
            <div className={classes.scaffoldingsContainer}>
                {Object.values(data).map(scaffold => (
                    <Scaffolding
                        key={scaffold.id}
                        data={scaffold}
                        image={scaffold.image}
                        onClick={() => props.addToCart(scaffold.images)}
                    />
                ))}
            </div>
        </div>
    );
}

export default Scaffoldings;
