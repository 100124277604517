import React, {useEffect, useState} from 'react';
import '../modules/NavBar.module.scss';
import {Button} from "@nextui-org/react";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

interface NavBarProps {
    scrollToComponent : (arg0: string) => void
}

function NavBar({scrollToComponent} : NavBarProps) {
    const [isNavBarVisible, setIsNavBarVisible] = useState(true);
    const { t } = useTranslation();
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;

            const threshold = 300;

            setIsNavBarVisible(currentScrollPos < threshold);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [ t ]);

    const handleLanguageChange = () => {
        if (i18n.language === 'en') {
            i18n.changeLanguage('ka');
        } else {
            i18n.changeLanguage('en');
        }
    };

    const handleClickContact = () => {
        scrollToComponent('contactRef');
    };

    const handleClickScaffoldings = () => {
        scrollToComponent('scaffoldingsRef');
    };

    return (
        <nav style={{transition: 'transform 0.3s ease-in-out',
            transform: `translateY(${isNavBarVisible ? '0' : '-100%'})`}}>
            <div >
                <ul>
                    <li onClick={handleClickContact}>{t('nav.contact')}</li>
                    <li onClick={handleClickScaffoldings}>{t('nav.scaffoldings')}</li>

                </ul>
                <img src="/skyhookLogo.png" alt=""/>
                <ul>
                    {/*<li>{t('nav.cart')}</li>*/}
                    <li><Button onClick={handleLanguageChange}>{t('nav.changeLang')}</Button></li>
                </ul>
            </div>

        </nav>
    );
}

export default NavBar;