import React from 'react';
import classes from '../modules/Scaffolding.module.scss';
import {ScaffoldingProps} from "../models/scaffolding.model";
import i18n from 'i18next';



function Scaffolding(props : ScaffoldingProps) {
    return (
        <div className={classes.scaffolding} onClick={props.onClick}>
            <img src={props.image} alt=""/>
            <div className={classes.details}>
                <span className={classes.title}>{ i18n.language === 'en' ? props.data.titleEng : props.data.titleGeo }</span>
                <span className={classes.info}>{ i18n.language === 'en' ? props.data.descEng : props.data.descGeo }</span>
            </div>
        </div>
    );
}

export default Scaffolding;