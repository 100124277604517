import React, { useRef, useState, useEffect } from 'react';
import './modules/App.module.scss';
import NavBar from "./components/NavBar";
import Welcome from "./components/Welcome";
import Scaffoldings from "./components/Scaffoldings";
import Footer from "./components/Footer";
import { NextUIProvider } from "@nextui-org/react";
import Modal from 'react-modal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import scaffoldingData from "./content/scaffoldings.json";
import { ScaffoldingItem } from "./models/scaffolding.model";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from './locales/en.json';
import kaTranslation from './locales/ka.json';
import Contact from "./components/Contact";
import Services from "./components/Services";

Modal.setAppElement('#root');

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: enTranslation },
            ka: { translation: kaTranslation }
        },
        lng: 'ka',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });

function App() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedScaffolding, setSelectedScaffolding] = useState<ScaffoldingItem | null>(null);
    const [enlargedImage, setEnlargedImage] = useState<string | null>(null);
    const [orientation, setOrientation] = useState('portrait');
    const contactRef = useRef<HTMLDivElement>(null);
    const scaffoldingsRef = useRef<HTMLDivElement>(null);
    const servicesRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleOrientationChange = () => {
            if (window.orientation === 90 || window.orientation === -90) {
                setOrientation('landscape');
            } else {
                setOrientation('portrait');
            }
        };

        window.addEventListener('orientationchange', handleOrientationChange);
        handleOrientationChange(); // Initial check

        return () => {
            window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging: function(i: number) {
            return (
                <div>
                    {selectedScaffolding ? (
                        <img
                            src={selectedScaffolding.images[i]}
                            alt={`Thumbnail ${i}`}
                            style={{
                                width: '50px',
                                height: '50px',
                                objectFit: 'cover',
                                borderRadius: '5px',
                                overflow: 'hidden',
                                border: '1px solid #ccc'
                            }}
                        />
                    ) : (
                        <div style={{ width: '50px', height: '50px' }}></div>
                    )}
                </div>
            );
        },
    };

    const handleAddToCart = (images: string[]) => {
        const scaffoldingId = Object.keys(scaffoldingData).find(key =>
            scaffoldingData[key as keyof typeof scaffoldingData].images === images
        );

        if (scaffoldingId) {
            const scaffolding = scaffoldingData[scaffoldingId as keyof typeof scaffoldingData];
            setSelectedScaffolding(scaffolding);
            setModalIsOpen(true);
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const openEnlargedImage = (image: string) => {
        setEnlargedImage(image);
    };

    const closeEnlargedImage = () => {
        setEnlargedImage(null);
    };

    const scrollToComponent = (refName: string) => {
        const ref = refName === 'contactRef' ? contactRef : refName === 'scaffoldingsRef' ? scaffoldingsRef : servicesRef;
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <NextUIProvider>
            <NavBar scrollToComponent={scrollToComponent} />
            <Welcome scrollToComponent={scrollToComponent} />
            <div ref={scaffoldingsRef}>
                <Scaffoldings addToCart={handleAddToCart} />
            </div>
            <div ref={servicesRef}>
                <Services />
            </div>
            <div ref={contactRef}>
                <Contact/>
            </div>
            <Footer />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Image Carousel"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        height: '80%',
                        padding: '20px',
                        overflow: 'hidden',
                        backgroundImage: 'url("./construction-background-lighter.jpg")'
                    }
                }}
            >
                {selectedScaffolding && (
                    <>
                        <h1>{selectedScaffolding.titleGeo}</h1>
                        <p>{selectedScaffolding.descGeo}</p>
                        <Slider {...settings} className="slick-carousel">
                            {selectedScaffolding.images.map((image, index) => (
                                <div key={index} onClick={() => openEnlargedImage(image)}>
                                    <img
                                        src={image}
                                        alt={`Scaffolding ${index}`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            maxHeight: '600px',
                                            cursor: 'pointer',
                                        }}
                                    />
                                </div>
                            ))}
                        </Slider>
                        <button onClick={closeModal} style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            zIndex: 1000,
                            backgroundColor: '#242424',
                            color: '#fff',
                            border: 'none',
                            padding: '10px',
                            cursor: 'pointer',
                            borderRadius: '5px'
                        }}>Close</button>
                    </>
                )}
            </Modal>
            <Modal
                isOpen={enlargedImage !== null}
                onRequestClose={closeEnlargedImage}
                contentLabel="Enlarged Image"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.9)',
                        zIndex: 1000
                    },
                    content: {
                        position: 'fixed', // Changed to fixed to cover the entire viewport
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        border: 'none',
                        background: 'none',
                        overflow: 'hidden',
                        WebkitOverflowScrolling: 'touch',
                        outline: 'none',
                        padding: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                    }
                }}
            >
                {enlargedImage && (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                    }}>
                        <img
                            src={enlargedImage}
                            alt="Enlarged scaffolding"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                objectFit: 'contain',
                                transition: 'all 0.3s ease'
                            }}
                        />
                        <button
                            onClick={closeEnlargedImage}
                            style={{
                                position: 'absolute',
                                top: '20px',
                                right: '20px',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                color: '#fff',
                                border: 'none',
                                padding: '10px',
                                cursor: 'pointer',
                                borderRadius: '50%',
                                width: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '20px'
                            }}
                        >
                            ×
                        </button>
                    </div>
                )}
            </Modal>


        </NextUIProvider>
    );
}

export default App;