import React from 'react';
import classes from '../modules/Footer.module.scss'
import {useTranslation} from "react-i18next";

function Footer() {
    const { t } = useTranslation();
    return (
        <div className={classes.footer}>
            <div>
                <div className={classes.trademark}>
                    <div className={classes.ibex}>
                        <img src="/Icon.svg" alt=""/>
                        <span>IBEX</span>
                    </div>
                    <span>{t('footer.caption')}</span>
                </div>
                <div className={classes.follow}>
                    <span>{t('footer.followUs')}</span>
                    <a href="https://www.facebook.com/SkyHookGeorgia"><span>Facebook</span></a>
                    <a href="https://www.instagram.com/skyhookgeorgia/"><span>Instagram</span></a>
                </div>
            </div>
            <hr/>
            <span>Laim Construction © 2024</span>
        </div>
    );
}

export default Footer;